<template>
  <form-view
    :url="`/car/filling/${!dataForm.id ? 'save' : 'updateById'}`"
    :data-form="dataForm"
    v-loading="loading"
    :custom-validator="customValidator"
    :before-submit="beforeSubmit"
  >
    <el-row :gutter="25">
      <el-col :span="8">
        <el-form-item label="自编号" prop="carId">
          <el-select filterable v-model="dataForm.carId" @change="handleChangeCar">
            <el-option v-for="s in carList" :key="s.id" :label="s.number" :value="s.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="公司名称" prop="licensePlate">
          <el-input disabled v-model="dataForm.licensePlate"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-table
      :data="tableData"
      border
      style="width: 100%">
      <el-table-column
        prop="job"
        label="岗位"
        width="180">
      </el-table-column>
      <el-table-column
        prop="name"
        label="姓名"
        width="180">
      </el-table-column>
      <el-table-column
        prop="response"
        label="职责">
      </el-table-column>
    </el-table>
    <el-row :gutter="25" style="margin-top: 20px">
      <el-col :span="8">
        <el-form-item label="事故日期" prop="businessLicenseFoundDate">
          <el-date-picker value-format="timestamp" v-model="dataForm.businessLicenseFoundDate"></el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="16">
        <el-form-item label="事故地址" prop="address">
          <el-input v-model="dataForm.address"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25" >
      <el-col :span="24">
        <el-form-item label="事故描述" prop="description">
          <el-input type="textarea" v-model="dataForm.description"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25">
      <el-col :span="24">
        <div class="license-area insurance">
          <div class="upload-wrapper">
            <image-uploader
              :limit="1"
              @add="addLicenseImage($event, 'contractAttachment')"
              @remove="removeLicenseImage('contractAttachment')"
              :images="beforeImageList || []"
              no-title
            ></image-uploader>
            <div class="title">事故描述附件</div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="25">
      <el-col :span="8">
        <el-form-item label="状态" prop="safetyStatus" >
          <el-select filterable v-model="dataForm.safetyStatus">
            <el-option v-for="s in companyList" :key="s.id" :label="s.companyName" :value="s.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="事故等级" prop="safetyLevel">
          <el-input v-model="dataForm.safetyLevel"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="责任人" prop="liableUserId">
          <el-select filterable v-model="dataForm.liableUserId">
            <el-option
              v-for="s in carGasType"
              :key="s.dictId"
              :label="s.dictName"
              :value="s.dictId"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25" >
      <el-col :span="24">
        <el-form-item label="处理结果" prop="safetyResult">
          <el-input type="textarea" v-model="dataForm.safetyResult"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25">
      <el-col :span="24">
        <div class="license-area insurance">
          <div class="upload-wrapper">
            <image-uploader
              :limit="1"
              @add="addLicenseImage($event, 'contractAttachment')"
              @remove="removeLicenseImage('contractAttachment')"
              :images="beforeImageList || []"
              no-title
            ></image-uploader>
            <div class="title">处理结果附件</div>
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="section-title-row" v-if="id">
      <div class="title">操作历史</div>
      <div class="line"></div>
    </div>
    <div class="record-area" v-if="id">
      <div class="record-row" v-for="(r, i) in records" :key="i">{{r}}</div>
    </div>
  </form-view>
</template>

<script>
import FormView from '@/components/templates/form-view'
import ImageUploader from '@/components/image-uploader'
import dayjs from 'dayjs'
import { operationTypes } from '@/utils/constants'

export default {
  name: 'car-oil-update',

  components: { FormView, ImageUploader },

  data () {
    return {
      loading: false,
      dataForm: {},
      carList: [],
      records: [],
      id: null,
      tableData: [{
        job: '驾驶员',
        name: '王小虎',
        response: '车长'
      }]
    }
  },

  created () {
    this.init()
  },

  methods: {
    async init () {
      const id = this.id = this.$route.query.id
      this.loading = true
      if (id) {
        const data = await this.$http({
          url: this.$http.adornUrl('/car/filling/getById'),
          method: 'post',
          data: id
        })
        if (data && data.code === 200) {
          this.dataForm = data.datas
          this.dataForm.testsType = parseInt(this.dataForm.testsType)
          this.dataForm.testsDate = dayjs(this.dataForm.testsDate).valueOf()
          if (this.dataForm.image) {
            this.dataForm.images = this.dataForm.image.split(',').map(j => ({ url: '/files' + j }))
            this.dataForm.imageList = this.dataForm.images.map(j => j.url.substring('/files'.length))
          } else {
            this.dataForm.images = []
            this.dataForm.imageList = []
          }
          const res = await this.$http({
            url: this.$http.adornUrl('/car/filling/operationRecord'),
            method: 'post',
            data: id
          })
          this.records = res.datas.map(d => {
            if (d.status === 3) {
              return `${d.createTime} ${d.nickName}进行了审批驳回操作，备注内容：${d.remarks}。`
            }
            return `${d.createTime} ${d.nickName}进行了${operationTypes[d.typeCode]}操作。`
          })
          this.loading = false
        }
      } else {
        this.dataForm = {
          carId: '',
          licensePlate: '',
          carSize: '',
          fillingNumber: '',
          unitPrice: '',
          totalPrice: '',
          imageList: []
        }
        this.loading = false
      }
      let data = await this.$http({
        url: this.$http.adornUrl('/car/list'),
        method: 'post',
        data: { carType: 1 }
      })
      this.carList = data.datas
    },

    handleChangeCar (carID) {
      const target = this.carList.find(c => c.id === carID)
      this.dataForm.licensePlate = target.licensePlate
      this.dataForm.carSize = target.carSize
    },

    calculate () {
      this.dataForm.totalPrice = this.dataForm.fillingNumber * this.dataForm.unitPrice
    },

    addImage (path) {
      this.dataForm.imageList.push(path)
    },

    removeImage (url) {
      this.dataForm.imageList.splice(this.dataForm.imageList.indexOf(url), 1)
    },

    customValidator () {
      return true
    },

    beforeSubmit (params) {
      params.image = params.imageList.join(',')
      return params
    }
  }
}
</script>

<style lang="scss" scoped>
  .insurance .license-area {
    justify-content: flex-start;
  }
  .license-area {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }
</style>
